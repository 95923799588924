import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-10 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Contact Us</h2>
        <img src={'../../../sg-logo.png'} width="50%" alt="SG Innovations logo" />
        <p className="mt-5">
          We are digital transformation company working on world class software development &
          consulting.
        </p>
        <p className="font-bold">e. info@sginnovations.com.np</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="#">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>
        </ul>
        <p className="mt-20 font-bold">Nepal Office</p>
        <p>Baluwatar - 4</p>
        <p>Kathmandu, Nepal</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Connect</h2>
        <ul className="mt-2 leading-loose">
          {/* <li>
            <a href="https://facebook.com">Facebook</a>
          </li> */}
          <li>
            <a href="mailto:info@sginnovations.com.np">Email</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/sginnovations">Linkedin</a>
          </li>
        </ul>
        <p className="mt-20 font-bold">Texas Office</p>
        <p>3381 West Highway 199</p>
        <p>Springtown, TX 76082</p>
      </div>
    </div>
  </footer>
);

export default Footer;
